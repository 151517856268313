import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../../styles/homeComponentStyles/hero.css"
import Slider from "react-slick"

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5000,
    usetransform: true,
    arrows: true,
    cssEase: "linear",
  }
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="hero-section hero_two">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="hero_text">
                    <h2>
                      Learn Anywhere <br />
                      Anytime From a <br />
                      Device Only
                    </h2>
                    <p>
                      Online learning is not the next big thing, <br />
                      it is now the greatest thing ever.
                    </p>
                    <Link to="#" className="more-link">
                      Get Started
                    </Link>
                  </div>
                  {/* <!-- /.hero_text --> */}
                </div>
                {/* <!-- /.col-md-6 col-sm-12--> */}
                <div className="col-md-6 col-sm-12">
                  <div className="hero_img">
                    <StaticImage
                      src="../../images/coding.png"
                      alt=""
                      className="coding"
                    />
                    <div className="hero_img_ani" id="scene">
                      <StaticImage
                        src="../../images/hero-men2.png"
                        alt="hero-men2"
                        data-depth="0.10"
                        className="layer"
                        placeholder="blurred"
                        layout="constrained"
                      />
                    </div>
                    <div className="hero_stu">
                      <h4> 13k+ Students</h4>
                      <StaticImage
                        src="../../images/hero_students.png"
                        alt="heroStudent"
                        placeholder="blurred"
                        layout="constrained"
                      />
                    </div>
                    <StaticImage
                      className="pencil"
                      src="../../images/pencil.png"
                      alt=""
                    />
                    {/* <!-- /.hero_stu--> */}
                  </div>
                </div>
                {/* <!-- /.col-md-6 col-sm-12--> */}
              </div>
              {/* <!-- /. row --> */}
            </div>
            {/* <!-- /. container --> */}
            <div className="hero_ellipse_icon">
              <StaticImage
                className="ellipse1"
                src="../../images/ellipse1.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse2"
                src="../../images/ellipse11.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse3"
                src="../../images/ellipse3.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse4"
                src="../../images/ellipse4.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse7"
                src="../../images/ellipse7.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse8"
                src="../../images/ellipse10.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse6"
                src="../../images/ellipse9.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
            </div>
            {/* <!-- /.hero_ellipse_icon--> */}
          </div>
        </div>

        {/* 2nd Slide goes here */}
        <div>
          <div className="hero-section hero_two">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="hero_text">
                    <h2>
                      {" "}
                      Learn Anywhere <br />
                      Anytime From a <br />
                      Device Only
                    </h2>
                    <p>
                      {" "}
                      Online learning is not the next big thing, <br />
                      it is now the greatest thing ever.{" "}
                    </p>
                    <Link to="#" className="more-link">
                      {" "}
                      Get Started{" "}
                    </Link>
                  </div>
                  {/* <!-- /.hero_text --> */}
                </div>
                {/* <!-- /.col-md-6 col-sm-12--> */}

                <div className="col-md-6 col-sm-12">
                  <div className="hero_img">
                    <StaticImage
                      src="../../images/coding.png"
                      alt=""
                      className="coding"
                    />
                    <div className="hero_img_ani" id="scene4">
                      <StaticImage
                        src="../../images/hero-men3.png"
                        alt=""
                        data-depth="0.10"
                        className="layer"
                        placeholder="blurred"
                        layout="constrained"
                      />
                    </div>
                    <div className="hero_stu">
                      <h4> 13k+ Students</h4>
                      <StaticImage
                        src="../../images/hero_students.png"
                        alt=""
                        placeholder="blurred"
                        layout="constrained"
                      />
                    </div>
                    <StaticImage
                      src="../../images/pencil.png"
                      alt=""
                      className="pencil"
                      placeholder="blurred"
                      layout="constrained"
                    />
                    {/* <!-- /.hero_stu--> */}
                  </div>
                </div>
                {/* <!-- /.col-md-6 col-sm-12--> */}
              </div>
              {/* <!-- /. row --> */}
            </div>
            {/* <!-- /. container --> */}
            <div className="hero_ellipse_icon">
              <StaticImage
                className="ellipse1"
                src="../../images/ellipse1.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse2"
                src="../../images/ellipse11.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse3"
                src="../../images/ellipse3.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse4"
                src="../../images/ellipse4.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse7"
                src="../../images/ellipse7.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse8"
                src="../../images/ellipse10.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
              <StaticImage
                className="ellipse6"
                src="../../images/ellipse9.png"
                alt=""
                placeholder="blurred"
                layout="constrained"
              />
            </div>
            {/* <!-- /.hero_ellipse_icon--> */}
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default Hero
