import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../../styles/homeComponentStyles/popularCategories.css"

const PopularCategories = () => {
  return (
    //         <!-- Start: Popular Categories Section
    // ==================================================-->
    <section className="category-section">
      {/* <!-- Container --> */}
      <div className="container">
        {/* <!-- Start: Heading --> */}
        <div className="base-header">
          <h3> Popular Categories </h3>
        </div>
        {/* <!-- End: Heading --> */}
        <div className="row">
          <div className="col-md-12" id="popula_cat">
            {/* <!-- category 1 --> */}
            <div className="category-item">
              <StaticImage
                src="../../images/cat-icon1.png"
                alt="catIcon1"
                placeholder="blurred"
                layout="constrained"
              />
              <h4>Design</h4>
            </div>
            {/* <!-- category 2 --> */}
            <div className="category-item">
              <StaticImage
                src="../../images/cat-icon2.png"
                alt="catIcon2"
                placeholder="blurred"
                layout="constrained"
              />
              <h4>Education</h4>
            </div>
            {/* <!-- category 3 --> */}
            <div className="category-item">
              <StaticImage
                src="../../images/cat-icon3.png"
                alt="catIcon3"
                placeholder="blurred"
                layout="constrained"
              />
              <h4>Craft</h4>
            </div>
            {/* <!-- category 4 --> */}
            <div className="category-item">
              <StaticImage
                src="../../images/cat-icon4.png"
                alt="catIcon4"
                placeholder="blurred"
                layout="constrained"
              />
              <h4>Marketing</h4>
            </div>
            {/* <!-- category 5 --> */}
            <div className="category-item">
              <StaticImage
                src="../../images/cat-icon2.png"
                alt="catIcon5"
                placeholder="blurred"
                layout="constrained"
              />
              <h4>Design</h4>
            </div>
          </div>
          {/* <!--/ col-md-12  --> */}
        </div>
        {/* <!--/ row - --> */}
      </div>
      {/* <!--/ Container - --> */}
    </section>
  )
}

export default PopularCategories
