import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "../../styles/homeComponentStyles/bestBook.css"
import "../../styles/global/owl.carousel.css"

const BestBook = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  }
  return (
    <section className="best-book-section">
      {/* <!-- Container --> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            {/* <!-- Start: Heading --> */}
            <div className="base-header">
              <h3> Some Best Books </h3>
            </div>
            {/* <!-- End: Heading --> */}
            {/* <!-- category 1 --> */}
            <div className="bbook_wrap">
              <div className="bbook_item">
                <i className="pe-7s-note2"></i>
                <span>
                  {" "}
                  132+ <br /> Total Pages{" "}
                </span>
              </div>
              <div className="bbook_item">
                <i className="pe-7s-download"></i>
                <span>
                  {" "}
                  237+ <br /> Downloads{" "}
                </span>
              </div>
              <div className="bbook_item">
                <i className="pe-7s-cup"></i>
                <span>
                  {" "}
                  13+ <br /> Award Won{" "}
                </span>
              </div>
            </div>
            <div className="bbook_btn">
              <Link to="#" className="more-link link-transparent">
                Free Preview
              </Link>
              <Link to="#" className="more-link">
                Download
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12" id="bestBook">
            <Slider {...settings}>
              {/* <!-- Best Book Image 1 --> */}
              <div className="bbook_crs_item">
                <StaticImage
                  src="../../images/best_book.jpg"
                  alt="bestbook"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
              {/* <!-- Best Book Image 2 --> */}
              <div className="bbook_crs_item">
                <StaticImage
                  src="../../images/best_book.jpg"
                  alt="bestbook"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
              {/* <!-- Best Book Image 3 --> */}
              <div className="bbook_crs_item">
                <StaticImage
                  src="../../images/best_book.jpg"
                  alt="bestbook"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
              {/* <!-- Best Book Image 4 --> */}
              <div className="bbook_crs_item">
                <StaticImage
                  src="../../images/best_book.jpg"
                  alt="bestbook"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
            </Slider>
          </div>
          {/* <!--/ col-md-12  --> */}
        </div>
        {/* <!--/ row - --> */}
      </div>
      {/* <!--/ Container - --> */}
    </section>
  )
}

export default BestBook
