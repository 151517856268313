import React from "react"
import { Link } from "gatsby"
import "../../styles/homeComponentStyles/newsletter.css"

const Newsletter = () => {
  return (
    <section className="newsletter-section pb-130">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="newsletter_wrap">
              {/* <!-- Start: Heading --> */}
              <div className="base-header">
                <h3> Sign Up To Our Newsletter </h3>
              </div>
              <span>
                Subscribe to our newsletter and get many <br />
                interesting things every week
              </span>
              <div className="newsletter_form">
                <input
                  className="newsletter_field"
                  name="search"
                  id="search_field"
                  type="text"
                  placeholder="Type your email address"
                />
                <Link to="#"> SUBSCRIBE </Link>
              </div>
              {/* <!-- Best Book Image 1 --> */}
            </div>
          </div>
        </div>
        {/* <!-- /. row --> */}
      </div>
      {/* <!-- /. container --> */}
    </section>
  )
}

export default Newsletter
