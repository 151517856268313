import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homeComponents/Hero"
import WorkFlow from "../components/homeComponents/WorkFlow"
import AboutUs from "../components/homeComponents/AboutUs"
import PopularCategories from "../components/homeComponents/PopularCategories"
// import FeaturedCourses from "../homeComponents/FeaturedCourses"
import BestBook from "../components/homeComponents/BestBook"
import LearnerFeedback from "../components/homeComponents/LearnerFeedback"
import Newsletter from "../components/homeComponents/Newsletter"


export default function Home() {
  return (
  <Layout>
    <Seo title="Home" />
      <Hero />
      <WorkFlow />
      <AboutUs />
      <PopularCategories />
      {/* <FeaturedCourses /> */}
      <BestBook />
      <LearnerFeedback />
      <Newsletter />
  </Layout>
    )
}
